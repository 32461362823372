
<template>
<div class="bg">
  
    <p style="border-bottom: 1px solid #999;color:#fff;text-align: center;padding-bottom :10px ;">田间调查</p>
<div>

    <p>调查地点</p>
    <input type="text" v-model="card.Place" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="xx市名xx县区名xx乡镇xx村" id="tipinput">
    <div style="width:100%;height: 300px;margin-top: 20px;" id="wgybMap1"></div>
    <p>经纬度</p>
    <input type="text" v-model="lnglat" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="" @input="changemap">
    <p>调查日期</p>
    <input type="text" v-model="card.Survey_Time" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;"  @click="show=true">
    <van-icon name="calendar" />
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }" >
     <van-datetime-picker
  v-model="currentDate"
  type="datetime"
  @confirm="confirm"
  @cancel="show=false"
/>
    </van-popup>
    <!-- <p>调查部门</p>
    <input type="text" v-model="card.Investigador" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder=""> -->
    <p>调查人员</p>
    <input type="text" v-model="card.Investigador" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="">
    <p>联系电话</p>
    <input type="text" v-model="card.Phone" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="">
    <p>调查类型</p>
    <input type="text" v-model="card.Items" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="">
    <p>联系结果</p>
    <textarea   v-model="card.Survey_Result" rows="3" style="width:100%;background:none;border:1px solid #999;padding: 5px;box-sizing: border-box;color: #fff;" placeholder="" ></textarea>
    <p>图片上传</p>
    <van-uploader v-model="fileList" multiple  :after-read="afterRead"/>
    <p class="bottom"><button class="his" @click="topath()">历史记录</button><button class="downup">我要上报</button></p>
</div>
</div>
</template>

<script>
import { gettime } from "@/utils/common.js"
import router from '../../router'
var mytdMap;
export default {
  name: '',
  components: {},
  data () {
    return {
        fileList:[],
        bumen:'',
        renyuan:'',
        dianhua:'',
        leixing:'',
        jieguo:'',
        show:false,
        currentDate:null,
        lnglat:'',
        time:'',
        didian:'',
        card:{
          "Id": 0,
  "Name": "",
  "Place": "",
  "Lng": "",
  "Lat": "",
  "Items": "",
  "Investigador": "",
  "Survey_Time": "",
  "Survey_Result": "",
  "Survey_File": "",
  "Areacode": "1304",
  "AddUser": 0,
  "CreateTime": "",
  "LastTime": "",
  'Phone':''
        }
    }
  },
  created () { },
  mounted () {
    setTimeout(() => {
      this.mapCreat();
    }, 500);
   },
  methods: {
    mapCreat() {
      // 来源于index.html中引入的 ./public/js/map/gdMap.js
      tdMap.map = new AMap.Map("wgybMap1", {
        center: [mapCenter[0], mapCenter[1]],
        resizeEnable: true,
        expandZoomRange: true,
        zoomEnable: true,
        scrollWheel: true,
        zoom: 8,
      });
      mytdMap = tdMap;
      mytdMap.addAreaLine(230200, 3);
      mytdMap.map.on("click", (ev) => {
        var { lat, lng } = ev.lnglat;
        this.card.Lat=lat
        this.card.Lng=lng
        this.lnglat=lat+','+ lng
        mytdMap.clearMark(); // 清除标注点
        let that=this
        mytdMap.addMark(lng, lat, require("@/assets/dianwei.png"), ""); // 添加标注点
        AMap.plugin(["AMap.Geocoder"], function() {
   let  geocoder = new AMap.Geocoder({
      city:'全国'
    });
    var lnglat=[ev.lnglat.lng, ev.lnglat.lat];
    geocoder.getAddress(lnglat,function (status,result){
      console.log(result);
      if (status === "complete" && result.info === "OK") {

//详细地址
        that.card.Place=result.regeocode.formattedAddress
      }
    })
  });
      });

    console.log(mytdMap.map);
    // mytdMap.map.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], function(){
    //     var auto = new AMap.AutoComplete(autoOptions);
    //     var placeSearch = new AMap.PlaceSearch({
    //         map: mytdMap.map
    //     });  //构造地点查询类
    //     auto.on("select", select);//注册监听，当选中某条记录时会触发
    //     function select(e) {
    //       console.log(e);
    //         placeSearch.setCity(e.poi.adcode);
    //         placeSearch.search(e.poi.name);  //关键字查询查询
    //     }
    // });
let that=this
    AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], function() { 
                       const autoOptions = {          
                        // 使用联想输入的input的div的id          
                        input: 'tipinput' ,   
                        city: "邯郸市",
                    }       
            const autocomplete = new AMap.Autocomplete(autoOptions) 
            const placeSearch = new AMap.PlaceSearch({
            city: '长沙',         
            map: mytdMap.map        
            })        
            AMap.event.addListener(autocomplete, 'select', function(e){
              console.log(e);   
           let lng = e.poi.location.lng
           let lat=e.poi.location.lat
           that.card.Lat=lat
           that.card.Lng=lng
           that.lnglat=lat+','+ lng
            mytdMap.clearMark(); // 清除标注点
        mytdMap.addMark(lng, lat, require("@/assets/dianwei.png"), ""); // 添加标注点
            })      
            })    
       
           
    },
    confirm(date){
console.log(gettime(date));
this.card.Survey_Time=gettime(date)
this.show=false
    },
    changemap(){
      let that=this
console.log(this.lnglat);
let lat=this.lnglat.split(',')[0]
let lng=this.lnglat.split(',')[1]
mytdMap.clearMark(); // 清除标注点
        mytdMap.addMark(lng,lat , require("@/assets/dianwei.png"), ""); // 添加标注点
        AMap.plugin(["AMap.Geocoder"], function() {
   let  geocoder = new AMap.Geocoder({
      city:'全国'
    });
    geocoder.getAddress([lng,lat],function (status,result){
      console.log(result);
      if (status === "complete" && result.info === "OK") {
//详细地址
        that.card.Place=result.regeocode.formattedAddress
      }
    })
  });
    },
    topath(){
      console.log(router);
      router.push('/his')
      // this.$router.go('/his')
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      var formData = new FormData();
      var fileType = 'file'; //拼接文件存储目录名
      formData.append("filename", file.file.name);
      // const env = process.env.NODE_ENV;
      // const window = env === "production" ? window.configs.otherApi : "";
      axios.post(window.apiSrc + window.apiport + '/api/Uploader/UploadFile?fileType=调查记录', formData,
        { 'Content-type': 'multipart/form-data', headers: { Authorization: 'Bearer ' + sessionStorage.getItem("access_token") } }).then(res => {
          if (res.data.code == 200) {
            console.log(this.card);
            this.fileList.push(res.data.url)
            if(this.card.Survey_File.split('*')==0){
              this.card.Survey_File=res.data.url
            }else{
              this.card.Survey_File=this.card.Survey_File+'*'+res.data.url
            }
           
          } else {
           
          }
        })
    },
    
   },
  computed: {}
}
</script>
<style scoped lang='scss'>
.bg{
    background-image: url('~@/assets/images/shbg.jpg');
    width: 100%;
    height:100%;
    overflow-y: auto;
    padding:20px;
    box-sizing: border-box;
    overflow-x:hidden ;
    background-size:100% 100%;
}
p{
    margin: 5px;
}
.bottom{
  display: flex;
  justify-content: space-around;
  button{
    border-radius: 5px;
    padding:5px 25px;
    box-sizing: border-box;
    font-size: 16px;
    border:none;
  }
  .his{
    background: rgba(0,0,0,0.3);
    
  }
  .downup{
    background: rgb(72,157,240);
  }
}
</style>
