// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/shbg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".bg[data-v-1a0ab305]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:100%;overflow-y:auto;padding:.02667rem;box-sizing:border-box;overflow-x:hidden;background-size:100% 100%}p[data-v-1a0ab305]{margin:.00667rem}.bottom[data-v-1a0ab305]{display:-webkit-box;display:-webkit-flex;display:flex;-webkit-justify-content:space-around;justify-content:space-around}.bottom button[data-v-1a0ab305]{border-radius:.00667rem;padding:.00667rem .03333rem;box-sizing:border-box;font-size:.02133rem;border:none}.bottom .his[data-v-1a0ab305]{background:rgba(0,0,0,.3)}.bottom .downup[data-v-1a0ab305]{background:#489df0}", ""]);
// Exports
module.exports = exports;
